import React, {useContext} from 'react';
import type {ReactionType} from '../../lib/constants';
import {AppContext} from '../../lib/context';
import {getCssRouteDefinition, getJSXRouteDefinition} from '../../lib/replaceWithSSPComponents';
import SEORoute from '../SEORoute';

type ReactionTypeProps = ReactionType.WAS_IT_HELPFUL | ReactionType.THANKS;

export type PageFeedback = {
  text: ReactionTypeProps;
  emojiList: ReactionTypeProps;
  positionLeft?: boolean;
  emojiSize: number;
  emojiHoverSize: number;
};

const Page: React.FC = props => {
  const context = useContext(AppContext);

  if (!context || !context.gem) {
    return null;
  }

  const {query, gem} = context;

  const {title} = gem;
  const {description} = gem;
  const htmlString = getJSXRouteDefinition(context) || '';
  const cssString = getCssRouteDefinition(context) || '';

  return (
    <SEORoute
      title={title}
      description={description}
      route={{
        bindings: {
          ...props,
          ...{...context, query: {...context.query}},
        },
        templateDefinition: query.templateDefinition,
        htmlString,
        cssString,
      }}
    />
  );
};

export default Page;
