import React from 'react';
import type {FC} from 'react';
import type {Props as RouteProps} from '../JSXRouteParser/JSXRouteParser';
import SEOProvider from '../SEOProvider';
import JSXRouteParser from '../JSXRouteParser/';

export type Props = {
  title?: string;
  description?: string;
  route: RouteProps;
};

const SEORoute: FC<Props> = props => {
  const {title = '', description = '', route} = props;

  return (
    <>
      <SEOProvider title={title} description={description} />
      <JSXRouteParser {...route} />
    </>
  );
};

export default SEORoute;
