import React, {useContext} from 'react';
import {AppContext} from '../../lib/context';
import {getCssRouteDefinition, getJSXRouteDefinition} from '../../lib/replaceWithSSPComponents';
import SEORoute from '../SEORoute';

const Folder: React.FC = () => {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const {folder, query} = context;
  const htmlString = getJSXRouteDefinition(context) || '';
  const cssString = getCssRouteDefinition(context) || '';

  return (
    <SEORoute
      title={folder?.title}
      description={folder?.description}
      route={{
        bindings: context,
        templateDefinition: query.templateDefinition,
        htmlString,
        cssString,
      }}
    />
  );
};

export default Folder;
