import React, {useContext} from 'react';
import type {NextPage} from 'next';
import {getCssRouteDefinition, getJSXRouteDefinition} from '../../lib/replaceWithSSPComponents';
import {AppContext} from '../../lib/context';
import {handleBodyScrollChange} from '../../lib/helpers/clientSideHelpers';
import SEORoute from '../SEORoute';

const Root: NextPage = () => {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  const htmlString = getJSXRouteDefinition(context) || '';
  const cssString = getCssRouteDefinition(context) || '';

  return (
    <SEORoute
      route={{
        bindings: {
          ...context,
          onBodyScrollChange: handleBodyScrollChange,
        },
        templateDefinition: context.query.templateDefinition,
        htmlString,
        cssString,
      }}
    />
  );
};

export default Root;
