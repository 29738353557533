import React, {Fragment, useContext, useEffect} from 'react';
import {compact, isEmpty} from 'lodash-es';
import Head from 'next/head';
import {NextSeo} from 'next-seo';
import type {NextSeoProps} from 'next-seo';
import type {OpenGraph, SEOProviderProps} from '../../lib/types';
import type {AppInfo} from '../../lib/types';
import {getTitleString, stripDescription} from '../../lib/helpers/helpers';
import {AppContext} from '../../lib/context';
import {isBrowser} from '../../lib/helpers/browserHelpers';

const getSeoConfig = ({
  title,
  description,
  images,
  settings,
}: OpenGraph & AppInfo): NextSeoProps => {
  const openGraph = {
    title,
    ...(!isEmpty(images) && {images}),
  };

  return {
    description,
    twitter: {
      cardType: 'summary',
      site: '',
      handle: '',
    },
    openGraph: {
      url: '',
      type: '',
      description: '',
      defaultImageHeight: 0,
      defaultImageWidth: 0,
      locale: 'en_US',
      // eslint-disable-next-line camelcase
      site_name: '',
      article: {
        publishedTime: '',
        modifiedTime: '',
        expirationTime: '',
        authors: [''],
        section: '',
        tags: [''],
      },
      ...openGraph,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    additionalMetaTags: settings.additionalMetaTags ?? [],
  };
};
const initGA = async (googleAnalyticsID: string) => {
  const {default: ReactGA} = await import('react-ga4');
  ReactGA.initialize(googleAnalyticsID);
};

const SEOProvider: React.FC<SEOProviderProps> = ({
  children,
  description: descriptionOverride,
  openGraph,
  title,
}) => {
  const context = useContext(AppContext);

  useEffect(() => {
    const googleAnalyticsID = context?.query?.appInfo?.settings?.gaTrackingID;

    if (googleAnalyticsID && isBrowser()) {
      initGA(googleAnalyticsID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!context) {
    return null;
  }

  const {query} = context;

  const description = descriptionOverride || query.appInfo?.settings?.description || '';
  const {appInfo} = query;
  const titleString = getTitleString(appInfo?.settings.name, title);
  const descriptionString = description ? stripDescription(description) : null;

  const {socialMedia} = appInfo?.settings?.images || {};
  const config = {
    ...appInfo,
    title: titleString,
    description: descriptionString || '',
    images:
      openGraph?.images ||
      compact([
        socialMedia && {
          url: appInfo.settings.images.socialMedia || '',
        },
      ]),
  };

  return (
    <Fragment>
      <NextSeo {...getSeoConfig(config)} />
      <Head>
        <title>{titleString}</title>
      </Head>
      {children}
    </Fragment>
  );
};

export default SEOProvider;
